import logo from "./logo.svg";
import "./App.css";
import "./assets/Fonts/Helvetica.ttf";
import phone from "./assets/phone.png";
import headbg from "./assets/headbackground.png";
import typing from "./assets/typing.png";
import MetaTags from "react-meta-tags";
import MessengerCustomerChat from "react-messenger-customer-chat";
function App() {
  return (
    <div className="App">
      <MessengerCustomerChat pageId="753577438049822" appId="740429100206249" />
      <MetaTags>
        <title>
          10 хуруугаар хурдан бичиж сурах программ, Арван хуруугаар хурдан бичиж
          сурах программ. Arvan huruugaar hurdan bichij surah programm. 10
          huruugaar hurdan bichij surah programm.
        </title>
        <meta
          name="description"
          content="10 хуруугаар хурдан бичиж сурах программ, Арван хуруугаар хурдан бичиж сурах программ. Arvan huruugaar hurdan bichij surah programm. 10 huruugaar hurdan bichij surah programm."
        />
        <meta
          property="og:title"
          content="10 хуруугаар хурдан бичиж сурах программ, Арван хуруугаар хурдан бичиж сурах программ. Arvan huruugaar hurdan bichij surah programm. 10 huruugaar hurdan bichij surah programm"
        />
        <meta
          property="og:image"
          content="https://fasttypemon.mn/poster/postermain.png"
        />
      </MetaTags>
      <div className="Menu">
        <table
          style={{
            width: "100%",
          }}
        >
          <tr>
            <td>
              <div style={{ textAlign: "left" }}>
                <table>
                  <tr>
                    <td className="Box"></td>
                    <td>
                      <div
                        className="Submenu"
                        style={{ fontFamily: "Helvetica" }}
                      >
                        НҮҮР
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
            <td>
              <div
                style={{
                  fontFamily: "Helvetica",
                  textAlign: "right",
                }}
              >
                <a href="https://fasttypemon.mn/download/fasttypemon.msi">
                  <span className="Submenu">ТАТАХ</span>
                </a>
                <a
                  className="Submenu"
                  href="https://fasttypemon.mn/download/zaavar.pdf"
                >
                  <span>ЗААВАР</span>
                </a>

                <a href="tel:+97699886117">
                  <span className="Submenu">
                    <img
                      src={phone}
                      style={{ marginBottom: "-2px", padding: "-2px" }}
                    />
                    99886117
                  </span>
                </a>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div className="row">
        <div className="column">
          <div className="col1data">
            <a href="https://fasttypemon.mn/download/fasttypemon.msi">
              <h2 className="button">ҮНЭГҮЙ ТАТАХ</h2>
            </a>
            <p style={{ color: "#4b84b3", fontSize: "20px" }}>
              Хурдан бичиж сурах <br /> минимал программ
            </p>
            <p
              style={{
                color: "#2553a4",
                fontFamily: "Helvetika",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Нэг суулгаад <br /> ХЯЗГААРГҮЙ АШИГЛА
            </p>
          </div>
        </div>
        <div className="column" style={{}}>
          <img src={typing} style={{ width: "100%", minHeight: "300px" }} />
        </div>
      </div>
      <div className="footertext">
        Программыг ашиглаж гарын зөв байрлалаар хурдан бичиж суран технологийн
        зуунд манлайлаарай.
      </div>
    </div>
  );
}

export default App;
